export const citiesInPoland = [
  {
    city: 'Będzin',
    voivodeship: 'Silesian',
  },
  {
    city: 'Bełchatów',
    voivodeship: 'Łódź',
  },
  {
    city: 'Biała Podlaska',
    voivodeship: 'Lublin',
  },
  {
    city: 'Białystok',
    voivodeship: 'Podlaskie',
  },
  {
    city: 'Bielsko-Biała',
    voivodeship: 'Silesian',
  },
  {
    city: 'Bydgoszcz',
    voivodeship: 'Kuyavian-Pomeranian',
  },
  {
    city: 'Bytom',
    voivodeship: 'Silesian',
  },
  {
    city: 'Chełm',
    voivodeship: 'Lublin',
  },
  {
    city: 'Chorzów',
    voivodeship: 'Silesian',
  },
  {
    city: 'Częstochowa',
    voivodeship: 'Silesian',
  },
  {
    city: 'Dąbrowa Górnicza',
    voivodeship: 'Silesian',
  },
  {
    city: 'Elbląg',
    voivodeship: 'Warmian-Masurian',
  },
  {
    city: 'Ełk',
    voivodeship: 'Warmian-Masurian',
  },
  {
    city: 'Gdańsk',
    voivodeship: 'Pomeranian',
  },
  {
    city: 'Gdynia',
    voivodeship: 'Pomeranian',
  },
  {
    city: 'Gliwice',
    voivodeship: 'Silesian',
  },
  {
    city: 'Głogów',
    voivodeship: 'Lower Silesian',
  },
  {
    city: 'Gniezno',
    voivodeship: 'Greater Poland',
  },
  {
    city: 'Gorzów Wielkopolski',
    voivodeship: 'Lubusz',
  },
  {
    city: 'Grudziądz',
    voivodeship: 'Kuyavian-Pomeranian',
  },
  {
    city: 'Inowrocław',
    voivodeship: 'Kuyavian-Pomeranian',
  },
  {
    city: 'Jastrzębie-Zdrój',
    voivodeship: 'Silesian',
  },
  {
    city: 'Jaworzno',
    voivodeship: 'Silesian',
  },
  {
    city: 'Jelenia Góra',
    voivodeship: 'Lower Silesian',
  },
  {
    city: 'Kalisz',
    voivodeship: 'Greater Poland',
  },
  {
    city: 'Katowice',
    voivodeship: 'Silesian',
  },
  {
    city: 'Kędzierzyn-Koźle',
    voivodeship: 'Opole',
  },
  {
    city: 'Kielce',
    voivodeship: 'Świętokrzyskie',
  },
  {
    city: 'Konin',
    voivodeship: 'Greater Poland',
  },
  {
    city: 'Koszalin',
    voivodeship: 'West Pomeranian',
  },
  {
    city: 'Kraków',
    voivodeship: 'Lesser Poland',
  },
  {
    city: 'Legionowo',
    voivodeship: 'Masovian',
  },
  {
    city: 'Legnica',
    voivodeship: 'Lower Silesian',
  },
  {
    city: 'Leszno',
    voivodeship: 'Greater Poland',
  },
  {
    city: 'Łódź',
    voivodeship: 'Łódź',
  },
  {
    city: 'Łomża',
    voivodeship: 'Podlaskie',
  },
  {
    city: 'Lubin',
    voivodeship: 'Lower Silesian',
  },
  {
    city: 'Lublin',
    voivodeship: 'Lublin',
  },
  {
    city: 'Mielec',
    voivodeship: 'Podkarpackie',
  },
  {
    city: 'Mysłowice',
    voivodeship: 'Silesian',
  },
  {
    city: 'Nowy Sącz',
    voivodeship: 'Lesser Poland',
  },
  {
    city: 'Olsztyn',
    voivodeship: 'Warmian-Masurian',
  },
  {
    city: 'Ostrołęka',
    voivodeship: 'Masovian',
  },
  {
    city: 'Ostrów Wielkopolski',
    voivodeship: 'Greater Poland',
  },
  {
    city: 'Ostrowiec Świętokrzyski',
    voivodeship: 'Świętokrzyskie',
  },
  {
    city: 'Pabianice',
    voivodeship: 'Łódź',
  },
  {
    city: 'Piekary Śląskie',
    voivodeship: 'Silesian',
  },
  {
    city: 'Piła',
    voivodeship: 'Greater Poland',
  },
  {
    city: 'Piotrków Trybunalski',
    voivodeship: 'Łódź',
  },
  {
    city: 'Płock',
    voivodeship: 'Masovian',
  },
  {
    city: 'Poznań',
    voivodeship: 'Greater Poland',
  },
  {
    city: 'Pruszków',
    voivodeship: 'Masovian',
  },
  {
    city: 'Przemyśl',
    voivodeship: 'Podkarpackie',
  },
  {
    city: 'Racibórz',
    voivodeship: 'Silesian',
  },
  {
    city: 'Radom',
    voivodeship: 'Masovian',
  },
  {
    city: 'Ruda Śląska',
    voivodeship: 'Silesian',
  },
  {
    city: 'Rybnik',
    voivodeship: 'Silesian',
  },
  {
    city: 'Rzeszów',
    voivodeship: 'Podkarpackie',
  },
  {
    city: 'Siedlce',
    voivodeship: 'Masovian',
  },
  {
    city: 'Siemianowice Śląskie',
    voivodeship: 'Silesian',
  },
  {
    city: 'Słupsk',
    voivodeship: 'Pomeranian',
  },
  {
    city: 'Sosnowiec',
    voivodeship: 'Silesian',
  },
  {
    city: 'Stalowa Wola',
    voivodeship: 'Podkarpackie',
  },
  {
    city: 'Stargard',
    voivodeship: 'West Pomeranian',
  },
  {
    city: 'Suwałki',
    voivodeship: 'Podlaskie',
  },
  {
    city: 'Świdnica',
    voivodeship: 'Lower Silesian',
  },
  {
    city: 'Szczecin',
    voivodeship: 'West Pomeranian',
  },
  {
    city: 'Tarnów',
    voivodeship: 'Lesser Poland',
  },
  {
    city: 'Tarnowskie Góry',
    voivodeship: 'Silesian',
  },
  {
    city: 'Tczew',
    voivodeship: 'Pomeranian',
  },
  {
    city: 'Tomaszów Mazowiecki',
    voivodeship: 'Łódź',
  },
  {
    city: 'Toruń',
    voivodeship: 'Kuyavian-Pomeranian',
  },
  {
    city: 'Tychy',
    voivodeship: 'Silesian',
  },
  {
    city: 'Wałbrzych',
    voivodeship: 'Lower Silesian',
  },
  {
    city: 'Warsaw',
    voivodeship: 'Masovian',
  },
  {
    city: 'Włocławek',
    voivodeship: 'Kuyavian-Pomeranian',
  },
  {
    city: 'Wrocław',
    voivodeship: 'Lower Silesian',
  },
  {
    city: 'Zabrze',
    voivodeship: 'Silesian',
  },
  {
    city: 'Zamość',
    voivodeship: 'Lublin',
  },
  {
    city: 'Zgierz',
    voivodeship: 'Łódź',
  },
  {
    city: 'Zielona Góra',
    voivodeship: 'Lubusz',
  },
  {
    city: 'Żory',
    voivodeship: 'Silesian',
  },
];
