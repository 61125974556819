export const TO_HOME = '/';
export const TO_PAYMENTS = '/payments';
export const TO_TRANSFERS = '/transfers';
export const TO_HISTORY = '/history';

//Auth and registration
export const TO_SIGN_IN = '/signin';
export const TO_SIGN_UP_START = '/signup-start';
export const TO_SIGN_UP_END = '/signup-end';
export const TO_SIGN_UP_FINISHED = '/signup-finished';
export const TO_VERIFICATION = '/verification';
export const TO_REGISTRATION = '/registration';
